/* autoprefixer grid: autoplace */

html, body {
    height: 100%;
    min-width: 320px;
    color: #000;
    background: #f3f3f9;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'StyreneA-Regular', sans-serif;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    -webkit-tap-highlight-color: transparent;
}

.overflow-hidden {
    overflow: hidden !important;
}

#root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

h1, h2, h3, h4, h5, h6 {
    font-family: sans-serif;
    font-weight: bold;
}

h2 {
    font-size: 60px;
}

h3 {
    font-size: 36px;
}

h4 {
    font-size: 24px;
}

input,
button,
select {
    outline: none;
    font-family: 'StyreneA-Regular', sans-serif;
}

button {
    cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
}
/*input[type="color"],*/
/*input[type="date"],*/
/*input[type="datetime"],*/
/*input[type="datetime-local"],*/
/*input[type="email"],*/
/*input[type="month"],*/
/*input[type="number"],*/
/*input[type="password"],*/
/*input[type="search"],*/
/*input[type="tel"],*/
/*input[type="text"],*/
/*input[type="time"],*/
/*input[type="url"],*/
/*input[type="week"],*/
/*select:focus,*/
/*textarea {*/
/*	font-size: 16px;*/
/*}*/
