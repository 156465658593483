@font-face {
    font-family: 'StyreneA-Regular';
    src: url('../fonts/subset-StyreneA-Regular.woff2') format('woff2'),
    url('../fonts/subset-StyreneA-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'StyreneA-Bold';
    src: url('../fonts/subset-StyreneA-Bold.woff2') format('woff2'),
    url('../fonts/subset-StyreneA-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Hundred-Display-Web';
    src: url('../fonts/subset-HundredDisplayWeb-Regular.woff2') format('woff2'),
    url('../fonts/subset-HundredDisplayWeb-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}